@media screen and (max-width: 480px) {
  body {
  }
  h1 {
    font-size: 2em;
    text-align: center
  }
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .secondary-cont {
    margin: 10px;
  }

   .img-div {
    /* transform: scale(0.9) */
    width: 100vw;
    height: 60vw;
  }

  .photos {
    /* width: 200px; */
    height: 60vw;
    /* transform: scale(0.4) */
  }
  /* .wide {
    width: 95%;
    height: 73%;
  }
  .tall {
    width: 52%;
    height: 73%;
  } */
  .bathroom {
    width: 92%;
    height: 73%;
  }
  .hallway {
    height: 73%;
    width: 52.2%;
  }
  .slider {
    height: 100% !important;
  }
  .slide {
    width: 100% !important;
    height: 100% !important;
  }
  #info {
    flex-direction: column;
    padding: 5px;
  }
  .section {
    width: 95%;
    margin: 5px 0 5px 0;
    background-color: #f1f1f1
  }
  .text-input {
    width: 80%;
  }
  .flex-container {
    flex-direction: column;
  }
  .btn {
    width: 50vw;
    -webkit-appearance: none;
  }
  footer {
    height: 100%;
    font-size: 1.5em;
    padding: 20px 0;
  }
}

@media screen and (max-width: 768px) {
  .btn {
    width: 30vw;
  }

  header {
    flex-direction: column;
  }
}
