:root {
  --secondary-color: 	#ccffff
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  background-color: #F8F8FF
}

.page-container {
  position: relative;
  min-height: 100vh;
}

header {
  height: 100%;
  background: #222;
  display: flex;
  justify-content: space-between;
  box-shadow: -5px 5px 4px;
  margin-bottom: 7px;
  /* border-bottom-left-radius: 10px; */
  /* border-bottom-right-radius: 10px; */
  align-items: center;
}

.contact-info {
  margin-right: 20px;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  font-size: 20px;
  color: #f1f1f1;
  text-decoration: none;
  background: #f1f1f1;
  color: #222;
  padding: 5px;
  width: 90px;
  text-align: center;
  border-radius: 20px;
}
.contact-info:hover {
  background: lightgrey
}
.secondary-cont {
  display: flex;
}

#contact-anchor {
  background: #222;
  color: #f1f1f1;
  border: 1px solid #f1f1f1
}
#contact-anchor:hover {
  background-color: lightgrey;
  color: black;
}

#logo {
  width: 90px;
  height: 90px;
  margin: 5px;
}

h1 {
  padding: 5px;
  margin: 5px;
  font-family: 'Pacifico', cursive;
  color: #f1f1f1
}


/* .img-div {
  margin: auto;
  margin-bottom: 7px;
  display: flex;
  flex-direction: row-reverse;

  border: 2px solid black;
  padding: 5px;
  width: 50vw ;
  height: 36.5vw;
  box-shadow: -5px 5px 7px;

  overflow-y: hidden;
  overflow-x: auto;
  direction: rtl;

} */


#info-section {
  font-size: 1.2em;
  height: 100%;
  text-align: left;
}

.photos {
  height: 100%;
}

/* .slider {
  height: 100% !important;
} */

#info {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 0;
}

.section {
  width: 45%;
  background: var(--secondary-color);
  box-shadow: -5px 5px 5px #333;

  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  /* font-family: 'Cormorant Garamond', serif; */
}

h2 {
  text-align: center;
  font-size: 2em;
}

footer {
  background: #222;
  height: 10vh;
  width: 100%;
  text-align: center;
  margin: 0;
}
h4 {
margin: 0;
position: relative;
top: 50%;
color: #f1f1f1;
}


.text-input {
  width: 20vw;
  padding: 5px 10px;
  margin: 5px auto;
  border-radius: 20px;
  border: none;
  font-size: 1.3em;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  background: lightgrey
}
.flex-container {
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  align-items: center;
}

.btn {
  width: 10vw;
  padding: 5px 10px;
  margin: 5px auto;
  border-radius: 20px;
  border: none;
  font-size: 1.3em;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  background: #4CAF50;
}
.btn:hover {
  background-color: green;
  transition: .7s all
}
